import kid_black_png_nobg from "../assets/images/kid_black_png_nobg.png";

import React from "react";
import BornGuiltyHeader from './header';
import { Container, Row, Col, Stack } from 'react-bootstrap';

import BornGuiltyHomeContent from './homeContent';
import BornGuiltyCharacters from './bornGuiltyCharacters';
import BornGuiltyReleaseCounter from './releaseCounter';
import BornGuiltyHomeContentMain from './homeContentMain';
import BornGuiltyTeam from './homeContentTeam';
import BornGuiltyRoadMap from './homeContentRoadMap';

const WelcomePage = () => {
    return (

        <Container fluid>
            <BornGuiltyHeader />
            <Container fluid="lg" style={{ maxWidth: "27%" }}>
                {/* <BornGuiltyFooter /> */}
            </Container>

            <Container fluid="xlg">
                <Row>
                    <Col style={{ paddingBottom: "10%" }}>
                        <Stack gap={0}>
                            <div className="bg-dark" id='home'>

                                <BornGuiltyHomeContentMain />
                            </div>
                            <div>
                                <p className='sub-title'>For each character a collection of 1000 randomly born NFTs living on the Polygon blockchain as ERC-721 tokens and hosted on IPFS.</p>
                            </div>
                            <div>
                                <BornGuiltyReleaseCounter />
                            </div>
                            <div className="bg-dark" id='story'>
                                <BornGuiltyHomeContent
                                    title={"Story"}
                                    content={"Some of us are lucky to be born behind the right border lines, some of us are born bearing the mistakes of our ancestors, some of us are born behind limitation of religious boundaries, some of us are born and emotionally consumed by the judgment of their community, we fight, we tell, you hear, we rise "}
                                    img={kid_black_png_nobg}
                                    // bgColor={"#3b4487"}
                                    imgBgColor={"white"}
                                    titleVariant={'dark'}
                                    contentVarian={'dark'} />
                            </div>
                            <div className="bg-dark" id='characters'>
                                <BornGuiltyCharacters title="Characters" />
                            </div>
                            <div className="bg-dark" id='mission'>
                                <BornGuiltyHomeContent
                                    title={"Mission"}
                                    content={"The world is a very small place now, there are no winning teams anymore, if anyone of us is hurt, we are all hurt. It is time to get rid of the labeling, tagging, and stereotyping. The world today is one, we need to stop fearing each other and learn how to accept our differences. We are nothing but decorated souls, but somehow today we find ourselves born guilty. This art is to remind us that we are still scared of our differences and we need to learn how it affects us."}
                                    titleVariant={'dark'}
                                    contentVarian={'dark'} />
                            </div>

                            <div className="bg-dark" id='roadmap'>
                                <BornGuiltyRoadMap />
                            </div>

                            <div className="bg-dark" id='team'>
                                <BornGuiltyTeam />
                            </div>

                            <div className="bg-dark" id='contact'>
                                <BornGuiltyHomeContent
                                    title={"Contact Us"}
                                    content={
                                        <div>
                                            <p>
                                                Info: info@bornguilty.net
                                            </p>
                                            <p>
                                                Eisa: eisa@bornguilty.net
                                            </p>
                                            <p>
                                                Hendawy: hendawy@bornguilty.net
                                            </p>
                                        </div>
                                    }
                                    titleVariant={'dark'}
                                    contentVarian={'dark'} />
                            </div>
                        </Stack>
                    </Col>
                </Row>
            </Container>

        </Container>

    );
};

export default WelcomePage;