import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const BornGuiltyHomeContent = ({ title, content, img, titleVariant, contentVarian, bgColor, imgBgColor }) => {
    return (
        <Container className="home-content" >
            <Row>
                <Col variant={titleVariant} className="home-content-section-header">{title}</Col>
            </Row>
            {
                img != null ?
                    <Row style={{ backgroundColor: bgColor }}>
                        <Col xs={12} md={8} variant={contentVarian} className="home-content-section-content">{content}</Col>
                        <Col xs={6} md={4} variant={contentVarian} style={{ alignItems: "center", height: "10%", backgroundColor: imgBgColor }}>
                            <img
                                // style={{transform: "scale(0.7,0.7)"}}
                                // className="d-block w-100"
                                style={{ width: "100%" }}
                                src={img}
                                alt="First slide"
                            />
                        </Col>
                        :
                        <div />
                    </Row>
                    :
                    <Row style={{ backgroundColor: bgColor }}>
                        <Col variant={contentVarian} className="home-content-section-content">{content}</Col>
                    </Row>
            }
        </Container >
    );
};

export default BornGuiltyHomeContent;