import './App.css';
import WelcomePage from './components/welcomePage';
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "./redux/data/dataActions";
import { Screen } from "./styles/globalStyles";

function App() {

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  // const data = useSelector((state) => state.data);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.bornGuilty !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.bornGuilty]);

  return <Screen>
    <WelcomePage />
  </Screen>;
}

export default App;
