import React from "react";
import { Container } from "../styles/globalStyles";

import background from "../assets/images/wide_screen.png";

const BornGuiltyHomeContentMain = () => {
    return (
        <Container style={{alignItems: "center"}}>
            <img
                alt="born guilty characters"
                style={{maxWidth: "70%"}}
                className="d-block w-100"
                src={background}
            />
        </Container>
    );
};

export default BornGuiltyHomeContentMain;