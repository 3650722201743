import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BornGuiltyTeamMember from "./teamMember";

const BornGuiltyTeam = () => {
    return (

        <Container fluid className="home-content" style={{ width: "100%", alignItems: "center" }}>
            {/* <Container fluid="sm" style={{ width: "100%", alignItems: "center" }}>
                <Row>
                    <Col variant="dark" className="home-content-section-header">Team</Col>
                </Row>
            </Container> */}
            <Container fluid="xxlg" className="home-content" style={{ backgroundColor: "white", width: "100%", alignItems: "center", textAlign: "center" }}>
                <Row>
                    <Col variant="dark" className="team-section-header">Team</Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col xs={6} md={4} lg="2">
                        <BornGuiltyTeamMember
                            name="Hendawy"
                            title="Software Engineer"
                        />
                    </Col>
                    <Col xs lg="2">
                        <BornGuiltyTeamMember
                            name="Eisa"
                            title="Author"
                        />
                    </Col>
                    <Col xs lg="2">
                        <BornGuiltyTeamMember
                            name="Sauce"
                            title="Artist"
                        />
                    </Col>
                    <Col xs lg="2">
                        <BornGuiltyTeamMember
                            name="Wayvz"
                            title="Admin"
                        />
                    </Col>
                </Row>
            </Container>

        </Container >
    );
};

export default BornGuiltyTeam;