import React from "react";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Discord, Facebook, Twitter, Instagram } from "react-bootstrap-icons";

const BornGuiltyHeader = () => {
    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="lg" sticky="top">
            <Container fluid="lg">
                <Navbar.Brand href="#" activeStyle={{ color: '#5754a8' }}>born guilty</Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
                        <Nav.Link activeStyle={{ color: '#5754a8' }} href="#story">STORY</Nav.Link>
                        <Nav.Link activeStyle={{ color: '#5754a8' }} href="#characters">CHARACTERS</Nav.Link>
                        <Nav.Link activeStyle={{ color: '#5754a8' }} href="#mission">MISSION</Nav.Link>
                        <Nav.Link activeStyle={{ color: '#5754a8' }} href="#roadmap">ROAD MAP</Nav.Link>
                        <Nav.Link activeStyle={{ color: '#5754a8' }} href="#team">TEAM</Nav.Link>
                        <Nav.Link activeStyle={{ color: '#5754a8' }} href="#contact">CONTACT US</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Nav.Link href="https://twitter.com/bornguiltynft" target={"_blank"}>
                    <Twitter color="lightskyblue" ></Twitter>
                </Nav.Link>
                <Nav.Link href="https://www.facebook.com/profile.php?id=100076489181885" target={"_blank"}>
                    <Facebook color="royalblue"></Facebook>
                </Nav.Link>
                <Nav.Link href="https://discord.gg/nmStSQq58b" target={"_blank"}>
                    <Discord color="CornflowerBlue"></Discord>
                </Nav.Link>
                <Nav.Link href="https://www.instagram.com/bornguilty.net_nft/" target={"_blank"}>
                    <Instagram color="red"></Instagram>
                </Nav.Link>
            </Container>
        </Navbar>
    );
};

export default BornGuiltyHeader;