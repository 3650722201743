// log
import store from "../store";
import BornGuiltyConfiguration from '../../config/config';

const fetchDataRequest = () => {
    return {
        type: "CHECK_DATA_REQUEST",
    };
};

const fetchDataSuccess = (payload) => {
    return {
        type: "CHECK_DATA_SUCCESS",
        payload: payload,
    };
};

const fetchDataFailed = (payload) => {
    return {
        type: "CHECK_DATA_FAILED",
        payload: payload,
    };
};

export const fetchData = (account) => {
    return async (dispatch) => {
        dispatch(fetchDataRequest());
        try {

            let allOwnerTokens = await store
                .getState()
                .blockchain.bornGuilty.methods.walletOfOwner(account)
                .call();

            let allOwnerTokensMetadata = [];

            for (let index = 1; index <= allOwnerTokens.length; index++) {
                let tokenURI = await store
                    .getState()
                    .blockchain.bornGuilty.methods.tokenURI(allOwnerTokens[index-1])
                    .call();

                fetch(tokenURI)
                    .then(response => response.json())
                    .then(metadata => {
                        allOwnerTokensMetadata[index] = metadata;
                    }).then(() =>
                        dispatch(
                            fetchDataSuccess({
                                // allLips,
                                allOwnerTokensMetadata,
                            })
                        )
                    );
            }
        } catch (err) {
            console.log(err);
            dispatch(fetchDataFailed("Could not load data from contract."));
        }
    };
};