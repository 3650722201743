import * as React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import Timeline from '@mui/lab/Timeline';
import BrushRoundedIcon from '@mui/icons-material/BrushRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import BoyRoundedIcon from '@mui/icons-material/BoyRounded';
import WomanRoundedIcon from '@mui/icons-material/WomanRounded';
import SouthAmericaRoundedIcon from '@mui/icons-material/SouthAmericaRounded';
import MosqueIcon from '@mui/icons-material/Mosque';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import MediationIcon from '@mui/icons-material/Mediation';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import RepeatIcon from '@mui/icons-material/Repeat';
import BornGuiltyRoadMapItem from './homeContentRoadMapItem';

const BornGuiltyRoadMap = ({ title, content, img, titleVariant, contentVarian, bgColor, imgBgColor }) => {
    return (
        <Container className="home-content" >
            <Row>
                <Col variant={titleVariant} className="home-content-section-header">Road Map</Col>
            </Row>
            <Row style={{ backgroundColor: bgColor }}>
                <Col variant={contentVarian} className="home-content-section-content">
                    <Timeline position="alternate">

                        <BornGuiltyRoadMapItem
                            milestone_time="2021"
                            milestone="Design 10 born guilty Characters"
                            milestone_subtext="to tell their stories"
                            date_align="right"
                            icon={<BrushRoundedIcon />}
                            icon_box_color="success"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="2022"
                            milestone="Character Reveal Schedule"
                            milestone_subtext={"Revealing one character every month, each character will tell a story and is limited to 1000 NFT."}
                            icon={<VisibilityRoundedIcon />}
                            icon_box_color="warning"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="January 2022"
                            milestone="Release Black Man character"
                            icon={<BoyRoundedIcon />}
                            icon_box_color="secondary"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="February 2022"
                            milestone="Release Black Woman character"
                            icon={<WomanRoundedIcon />}
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="March 2022"
                            milestone="Release Latin American character"
                            icon={<SouthAmericaRoundedIcon />}
                            icon_box_color="error"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="April 2022"
                            milestone="Release Native American character"
                            icon={<MediationIcon />}
                            icon_box_color="warning"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="May 2022"
                            milestone="Release Asian American character"
                            icon={<SentimentNeutralIcon />}
                            icon_box_color="info"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="June 2022"
                            milestone="Release Jewish Boy character"
                            icon={<RepeatIcon />}
                            icon_box_color="grey"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="July 2022"
                            milestone="Release Palestinian Boy character"
                            icon={<RepeatIcon />}
                            icon_box_color="success"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="August 2022"
                            milestone="Release North Korean character"
                            icon={<ExploreOffIcon />}
                            icon_box_color="error"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="September 2022"
                            milestone="Release Muslim Woman character"
                            icon={<MosqueIcon />}
                            icon_box_color="primary"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="October 2022"
                            milestone="Release Muslim Man character"
                            icon={<MosqueIcon />}
                            icon_box_color="primary"
                        />

                        <BornGuiltyRoadMapItem
                            milestone_time="December 2022"
                            milestone="Release the born guilty book"
                            milestone_subtext="with all characters’ stories and deliver an online version of the book to all born guilty NFTs holders through bornguilty.net"
                            icon={<MenuBookIcon />}
                            icon_box_color="success"
                        />

                    </Timeline>
                </Col>
            </Row>
        </Container >
    );
};

export default BornGuiltyRoadMap;