import React from "react";
import { Row, Col, Container } from "react-bootstrap";

const BornGuiltyCharacterContainer = ({ title, content, imgSrc1, imgSrc2, imgSrc3, imgSrc4 }) => {
    return (
        <Container style={{ paddingBottom: "7rem" }}>
            <Row style={{ width: "100%" }}>
                <Col style={{ maxWidth: "8%" }} />
                <Col style={{ fontSize: "3vw" }}>
                    {title}
                </Col>
            </Row>
            <Row style={{ width: "100%", height: "100%" }}>
                <Col style={{ maxWidth: "8%" }} />
                <Col style={{ width: "70%" }}>
                    <Row style={{ width: "100%", height: "50%" }}>
                        <Col style={{ width: "60%" }}>
                            <img fluid alt="" src={imgSrc1} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </Col>
                        <Col style={{ width: "60%" }}>
                            <img fluid alt="" src={imgSrc2} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </Col>
                    </Row>
                    <Row style={{ width: "100%", height: "50%" }}>
                        <Col style={{ width: "60%" }}>
                            <img fluid alt="" src={imgSrc3} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </Col>
                        <Col style={{ width: "60%" }}>
                            <img fluid alt="" src={imgSrc4} style={{ maxWidth: "100%", maxHeight: "100%" }} />
                        </Col>
                    </Row>
                </Col>
                <Col className="home-content-section-content" style={{ width: "22%", fontSize: "3vw" }}>{content}</Col>
            </Row>
        </Container>
    );
};

export default BornGuiltyCharacterContainer;