import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

// const Completed = () => <span>Mine Your NFT Now!</span>;
// const CountDown = () => <span>NFT Release Count Down</span>;

// const countDownRenderer = ({ days, hours, minutes, seconds, completed }) => {
//     if (completed) {
//         return <Completed />;
//     } else {
//         return <span className="timer"><span>Count Down</span> {days}:{hours}:{minutes}:{seconds}</span>;
//     }
// };

const BornGuiltyReleaseCounter = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date(`2022-01-14`) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                Minutes: Math.floor((difference / 1000 / 60) % 60),
                Seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            timerComponents.push(
                <span style={{ padding: "5px" }}>
                    0 {interval}{" "}
                </span>
            );
            return;
        }
        timerComponents.push(
            <span>
                {timeLeft[interval]} {interval}{" "}
            </span>
        );
    });
    return (
        <div>
            {
                timerComponents.length ?
                    <Container fluid style={{ backgroundColor: "#0d460d", width: "100%", color: "white", marginBottom: "3vh", minHeight: "4vh", display: "grid" }}>
                        <Row style={{ fontSize: "2.5vw", alignItems: "center" }}>
                            <Col style={{ textAlign: "end" }}>
                                NFT Release Count Down
                            </Col>
                            <Col style={{ textAlign: "center" }}>
                                {timerComponents[0]}
                            </Col>
                        </Row>
                        <Row style={{ fontSize: "0.95vw" }}>
                            <Col />
                            <Col style={{ textAlign: "center", maxHeight: "100%" }}>
                                {timerComponents[1]}
                                {timerComponents[2]}
                                {timerComponents[3]}
                            </Col>
                        </Row>
                    </Container>

                    :

                    <Container fluid style={{ width: "100%", color: "white", marginBottom: "3vh" }}>
                        <Row style={{ fontSize: "2.5vw" }}>
                            <Col style={{ textAlign: "center" }}>
                                <Button href="https://opensea.io/collection/born-guilty-black-man" target={"_blank"}>
                                    Get Your NFT Now in Opensea!
                                </Button>
                            </Col>
                        </Row>
                    </Container>
            }
        </div >
    );

    // return (
    //     <Container className="home-content" style={{ width: "100%" }}>
    //         {/* <Countdown date={new Date(`01/31/2022`)} renderer={countDownRenderer} className="timer">
    //             <Completed />
    //         </Countdown> */}
    //     </Container>
    // );
};

export default BornGuiltyReleaseCounter;