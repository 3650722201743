
import React from "react";
import { Carousel, Col, Container, Row } from "react-bootstrap";
import BornGuiltyCharacterContainer from "./bornGuiltyCharacterContainer";

import afro_american_1 from "../assets/images/afro_american_1.jpg";
import afro_american_2 from "../assets/images/afro_american_2.png";
import afro_american_3 from "../assets/images/afro_american_3.png";
import afro_american_4 from "../assets/images/afro_american_4.png";

const BornGuiltyCharacters = ({ title, titleVariant, contentVarian }) => {
    let blackBoyContent = <p>
        Discrimination
        <br />
        Exclusion
        <br />
        Inequality
        <br />
        Microaggression
        <br />
        Racism
        <br />
        Police Brutality
        <br />
        Prejudice
    </p>;

    return (
        <Container className="home-content" fluid="xlg">
            <Container fluid="lg">
                <Row>
                    <Col variant={titleVariant} className="home-content-section-header">{title}</Col>
                </Row>
            </Container>
            <Row style={{ flexDirection: "column" }}>
                <Col variant={contentVarian} className="home-content-section-content">
                    <Carousel>

                        <Carousel.Item>
                            <BornGuiltyCharacterContainer
                                title="born to face"
                                content={blackBoyContent}
                                imgSrc1={afro_american_1} 
                                imgSrc2={afro_american_2} 
                                imgSrc3={afro_american_3} 
                                imgSrc4={afro_american_4} 
                                />
                            <Carousel.Caption>
                                <h4>Black Man</h4>
                            </Carousel.Caption>
                        </Carousel.Item>

                    </Carousel>
                </Col>
            </Row>
        </Container>
    );
};

export default BornGuiltyCharacters;