import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import black_square from "../assets/images/black_square.png";

const BornGuiltyTeamMember = ({ name, title, link }) => {
    return (
        // style={{ backgroundColor: "white", width: "100%", alignItems: "center" }}
        <Container >
            <Row>
                <Col>
                    <Image src={black_square} style={{ maxWidth: "30%" }} />
                </Col>
            </Row>
            <Row>
                <Col style={{ fontWeight: "700", fontSize: "1.6rem" }}>{name}</Col>
            </Row>
            <Row>
                <Col>{title}</Col>
            </Row>
            <Row>
                <Col>{link}</Col>
            </Row>
        </Container >
    );
};

export default BornGuiltyTeamMember;