import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';

const BornGuiltyRoadMapItem = ({ milestone_time, milestone, milestone_subtext, icon, icon_box_color, date_align }) => {
    return (
        <TimelineItem>
            {
                milestone_time != null ?
                    <TimelineOppositeContent
                        sx={{ m: 'auto 0' }}
                        align={date_align}
                    >
                        {milestone_time}
                    </TimelineOppositeContent>
                    :
                    ""
            }
            <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot color={icon_box_color}>
                    {icon}
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" component="span">
                    {milestone}
                </Typography>
                {
                    milestone_subtext != null ?
                        <Typography style={{ color: "grey" }}>{milestone_subtext}</Typography>
                        :
                        ""
                }
            </TimelineContent>
        </TimelineItem>
    );
};

export default BornGuiltyRoadMapItem;